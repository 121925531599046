import lottie from 'lottie-web'
import 'rellax'

const mountScrollAnimation = () => {
  const lottieScrollAnimation = []
  Array.from(document.querySelectorAll('.scroll-motion')).forEach((el, index) => {
    lottieScrollAnimation[index] = lottie.loadAnimation({
      container: el,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/wp-content/themes/cocoessen_v1/assets/json/cocoessen_line.json',
    })
  })
}

const rellaxAnimation = () => {
  const rellaxIn = document.querySelectorAll('.rellax')
  const Rellax = require('rellax')
  rellaxIn.forEach((el) => {
    const rellax = new Rellax(el, {
      center: false,
      relativeToWrapper: true,
      wrapper: el.parentElement,
    })
    window.addEventListener('scroll', () => {
      rellax.refresh()
    })
  })
}

export { mountScrollAnimation, rellaxAnimation }
